export const PetList = [
  { name: 'Doomog', probability: 5.405, rarity: 'Normal', sum: 5.405 },
  { name: 'Doodle', probability: 5.405, rarity: 'Normal', sum: 10.81 },
  { name: 'Muka', probability: 5.405, rarity: 'Normal', sum: 16.215 },
  { name: 'Lobs', probability: 5.405, rarity: 'Normal', sum: 21.62 },
  { name: 'Rocco', probability: 5.405, rarity: 'Normal', sum: 27.025 },
  { name: 'Grog', probability: 5.405, rarity: 'Normal', sum: 32.43 },
  { name: 'Lizard', probability: 5.405, rarity: 'Normal', sum: 37.835 },
  { name: 'Piraku', probability: 5.405, rarity: 'Normal', sum: 43.24 },
  { name: 'Orga', probability: 5.405, rarity: 'Normal', sum: 48.645 },
  { name: 'Froggy', probability: 5.405, rarity: 'Normal', sum: 54.05 },
  { name: 'Crow', probability: 5.405, rarity: 'Normal', sum: 59.455 },
  { name: 'Vlan', probability: 5.405, rarity: 'Normal', sum: 64.86 },
  { name: 'Cava', probability: 5.405, rarity: 'Normal', sum: 70.265 },
  { name: 'Harpy', probability: 5.405, rarity: 'Normal', sum: 75.67 },
  { name: 'Ent Sprout', probability: 1.1, rarity: 'Fine', sum: 76.77 },
  { name: 'Spora', probability: 1.1, rarity: 'Fine', sum: 77.87 },
  { name: 'Dras', probability: 1.1, rarity: 'Fine', sum: 78.97 },
  { name: 'Mangora', probability: 1.1, rarity: 'Fine', sum: 80.07 },
  { name: 'Kobold', probability: 1.1, rarity: 'Fine', sum: 81.17 },
  { name: 'Golem', probability: 1.1, rarity: 'Fine', sum: 82.27 },
  { name: 'Beezly', probability: 1.1, rarity: 'Fine', sum: 83.37 },
  { name: 'Sunny Tino', probability: 1.1, rarity: 'Fine', sum: 84.47 },
  { name: 'Pumpkin', probability: 1.1, rarity: 'Fine', sum: 85.57 },
  { name: 'Potato', probability: 1.1, rarity: 'Fine', sum: 86.67 },
  { name: 'Anouk', probability: 1.1, rarity: 'Fine', sum: 87.77 },
  { name: 'Pappsi', probability: 1.1, rarity: 'Fine', sum: 88.87 },
  { name: 'Kobold Vice-Captain', probability: 1.1, rarity: 'Fine', sum: 89.97 },
  { name: 'Rapi', probability: 1.1, rarity: 'Fine', sum: 91.07 },
  { name: 'Baske', probability: 1.1, rarity: 'Fine', sum: 92.17 },
  { name: 'Jarod', probability: 1.1, rarity: 'Fine', sum: 93.27 },
  { name: 'Monsi', probability: 1.1, rarity: 'Fine', sum: 94.37 },
  { name: 'Paimon', probability: 1.1, rarity: 'Fine', sum: 95.47 },
  { name: 'Carlon', probability: 1.1, rarity: 'Fine', sum: 96.57 },
  { name: 'Sylfein', probability: 1.1, rarity: 'Fine', sum: 97.67 },
  { name: 'Kokas', probability: 0.0952, rarity: 'Rare', sum: 97.7652 },
  { name: 'Hoghorn', probability: 0.0952, rarity: 'Rare', sum: 97.8604 },
  { name: 'Poporo', probability: 0.0952, rarity: 'Rare', sum: 97.9556 },
  { name: 'Wisp', probability: 0.0952, rarity: 'Rare', sum: 98.0508 },
  { name: 'Dood', probability: 0.0952, rarity: 'Rare', sum: 98.146 },
  { name: 'Kami', probability: 0.0952, rarity: 'Rare', sum: 98.2412 },
  { name: 'Tino', probability: 0.0952, rarity: 'Rare', sum: 98.3364 },
  { name: 'Snowflake Yaka', probability: 0.0952, rarity: 'Rare', sum: 98.4316 },
  { name: 'Sand Yaka', probability: 0.0952, rarity: 'Rare', sum: 98.5268 },
  { name: 'Small Troll', probability: 0.0952, rarity: 'Rare', sum: 98.622 },
  { name: 'Mantino', probability: 0.0952, rarity: 'Rare', sum: 98.7172 },
  { name: 'Benati', probability: 0.0952, rarity: 'Rare', sum: 98.8124 },
  { name: 'Bauz', probability: 0.0952, rarity: 'Rare', sum: 98.9076 },
  { name: 'Malito', probability: 0.0952, rarity: 'Rare', sum: 99.0028 },
  { name: 'Kobold Captain', probability: 0.0952, rarity: 'Rare', sum: 99.098 },
  { name: 'Palin', probability: 0.0952, rarity: 'Rare', sum: 99.1932 },
  { name: 'Vanel', probability: 0.0952, rarity: 'Rare', sum: 99.2884 },
  { name: 'Likaon', probability: 0.0952, rarity: 'Rare', sum: 99.3836 },
  { name: 'Tetrola', probability: 0.0952, rarity: 'Rare', sum: 99.4788 },
  { name: 'Hoz', probability: 0.0952, rarity: 'Rare', sum: 99.574 },
  { name: 'Leaf Ent', probability: 0.0952, rarity: 'Rare', sum: 99.6692 },
  { name: 'Poma', probability: 0.0194, rarity: 'Epic', sum: 99.6886 },
  { name: 'Hegwid', probability: 0.0194, rarity: 'Epic', sum: 99.708 },
  { name: 'Little Baktar', probability: 0.0194, rarity: 'Epic', sum: 99.7274 },
  { name: 'Tina', probability: 0.0194, rarity: 'Epic', sum: 99.7468 },
  { name: 'Shakir', probability: 0.0194, rarity: 'Epic', sum: 99.7662 },
  { name: 'Baz', probability: 0.0194, rarity: 'Epic', sum: 99.7856 },
  { name: 'Pason', probability: 0.0194, rarity: 'Epic', sum: 99.805 },
  { name: 'Little Ruskal', probability: 0.0194, rarity: 'Epic', sum: 99.8244 },
  { name: 'Little Brokut', probability: 0.0194, rarity: 'Epic', sum: 99.8438 },
  { name: 'Rimska', probability: 0.0194, rarity: 'Epic', sum: 99.8632 },
  { name: 'Shura', probability: 0.0194, rarity: 'Epic', sum: 99.8826 },
  { name: 'Bhadon', probability: 0.0194, rarity: 'Epic', sum: 99.902 },
  { name: 'Slime', probability: 0.0194, rarity: 'Epic', sum: 99.9214 },
  { name: 'Zamonto', probability: 0.0194, rarity: 'Epic', sum: 99.9408 },
  { name: 'Payati', probability: 0.0194, rarity: 'Epic', sum: 99.9602 },
  { name: 'Kellia', probability: 0.0194, rarity: 'Epic', sum: 99.9796 },
  { name: 'Gaud', probability: 0.0194, rarity: 'Epic', sum: 99.999 },
  { name: 'Little Dinuks', probability: 0.0, rarity: 'Legendary' },
  { name: 'Little Artuman', probability: 0.0, rarity: 'Legendary' },
  { name: 'Little Falvinia', probability: 0.0, rarity: 'Legendary' },
  { name: 'Little Bamaron', probability: 0.0, rarity: 'Legendary' },
  { name: 'Pengdro', probability: 0.0, rarity: 'Legendary' }
]
